* {
    font-family: "Roboto", sans-serif;
}

.header{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    background-color: white;
}

.header .header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 70px;
    background-color: white;
    padding: 0 50px; 
}

.header-logo-container {
    position: relative;
    margin-right: 20px;
}

.header .header-logo-container a {
    color: var(--primary);
    font-size: 2rem;
    font-family: -apple-system, "Helvetica Neue", sans-serif, "Mier Book";
    font-weight: 500;
    font-style: normal;
    text-decoration: none;
}

.logoDesigenBottom {
    position: absolute;
    bottom: -3px;
    left: 0;
    width: 100%;
    height: 6px;
    background-color: var(--primary);
}

.logoDesigenBottom::after {
    content: "";
    position: absolute;
    background-color: white;
    right: 0px;
    bottom: 0px;
    border-left: 20px solid var(--primary);
    border-top: 3px solid transparent;
    border-bottom: 3px solid transparent;
}

.logoDesigenBottom::before {
    content: "";
    position: absolute;
    background-color: transparent;
    left: 0px;
    bottom: 1px;
    border-left: 20px solid white;
    border-top: 2px solid transparent;
    border-bottom: 2px solid transparent;
}

.search-container {
    position: relative;
    margin: 0 15px;
}

.search-container:hover .search-container-dropDown.show {
    display: flex;
}

.header .header-container .search-container-inner {
    padding: 0.6rem 0.3rem;
    width: 400px;
    border: 1px solid black;
    border-radius: 3px;
    align-self: flex-start;
    position: relative;
}

.search-icon-container {
    position: absolute;
    top: 3px;
    left: 10px;
}

.clear-icon {
    display: none;
}

.clear-icon.show {
    display: block;
    position: absolute;
    right: 5px;
    bottom: 1px;
    opacity: 0.8;
}

.search-container-inner input {
    border: none;
    outline: none;
    font-size: 1rem;
    padding-left: 40px;
    letter-spacing: 1px;
    width: 85%;
}

.search-container-dropDown {
    display: none;
    position: absolute;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* height: 22a0px; */
    flex-wrap: wrap;
    background-color: white;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.192);
    border-radius: 5px;
    z-index: 100;
    padding: 10px 0;
}

.search-container-dropDown p {
    cursor: pointer;
    padding: 0.3rem 0.5rem;
    border: 1px solid gray;
    border-radius: 8px;
    margin: 0.3rem;
    background-color: var(--preSecondary);
    opacity: 0.8;
}

.container-for-border {
    height: 1px;
    background-color: black;
    width: 100%;
    opacity: 0.3;
}


/* ***************************** HEADER TOP-RIGHT SECTION **********************/
.header-container .header-right {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.header .header-right-cont {
    display: flex;  
    align-items: center;
    padding: 0 0.7rem;
    position: relative;
    height: 100%;
}

.header .header-download-app{ 
    display: flex;
    align-items: center;
    justify-content: center;
}

.header-download-app .mobileIcon{
    padding-top: 3px;
    padding-right: 5px;
}

.header-right-cont.download-app-container{
    position: relative;
    padding-bottom: 1px;
}

 .download-app-container:hover{
    font-weight: 500;
    padding-bottom: 0;
    border-bottom: 2px solid var(--primary);
}
 
 .download-app-container:hover .download-app-dropDown{
  display: flex;
  color: black;
 }

.download-app-dropDown{
    position: absolute;
    width: 110%;
    background-color: white;
    top: 105%;
    left: -8px; 
    z-index: 12;
    display: none;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 10px 10px 20px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    box-shadow: 1px 4px 4px black;
    animation: 400ms ease-in 0s 1 normal none running fadeIn;
}

.android-app-download, .ios-app-download{ 
    margin-top: 1rem;
    width: 155px;
    height: 52px;
}
.android-app-download img, .ios-app-download img{
    width: 100%;
    height: 100%;
}
 

.header .header-right-cont span {
    cursor: pointer;
    text-align: center;
    letter-spacing: 1px;
    font-size: 1.1rem;
} 
.header .header-right-cont:hover {
    color: var(--primary);
}
 
.header .profile-cart-cont {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding: 0;
    margin: 0;

}

.header .profile-cont:hover,
.header .cart-cont:hover {
    color: var(--primary);
}

.profile-cont,
.cart-cont {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-left: 26px;
}

.profile-cart-cont .profile-icon,
.profile-cart-cont .cart-icon {
    height: 30px;
}

.profile-cont span,
.cart-cont span {
    font-size: 1rem !important;
}

.container-for-left-border {
    width: 1px;
    height: 40px;
    background-color: grey;
    opacity: 0.5;
}


/* ------------------------------RESPONSIVENESS BREAK POINTS------------------------- */

@media screen and (max-width: 1024px){
    .header .header-container .search-container-inner{
        max-width: 220px;
    }
    .header .header-container{
        justify-content: center;
        padding: 0 10px;
    }
    .profile-cont, .cart-cont{
        margin-left: 15px;
    }
}

@media screen and (max-width: 769px){
    .header-right-cont.newsroom{
        display: none;
    }

}