.homeContainer, .home-firstSection, .home,  
 .firstSection-feature, .firstSection-feature-item{
    display: flex; 
    justify-content: center; 
    align-items: center;
} 

.home{ 
    padding: 150px 50px 50px;  
    flex-direction: column;
}

.homeContainer{
   flex-direction: column;
   width: 90%;
   margin-bottom: 20px;
}

.home .strait-line-section{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 3rem 0 2rem;
}

.strait-line-section .strait-line{
    width: 22%;
    height: 1px;
    background-color: black;
    opacity: 0.5;
}

.home .strait-line-section span{
    font-size: 2.3rem;
    font-weight: 500;
    text-align: center;
}

.firstSection-feature-item>span{
    padding-left: 5px;
}
 

 
/* ------------------------------RESPONSIVENESS BREAK POINTS------------------------- */
@media screen and (max-width: 1024px){ 
    .homeContainer{ 
        width: 100%;
     }
}

@media screen and (max-width: 769px){ 
    .home{ 
        padding: 150px 25px 50px;  
    }
}