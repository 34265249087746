 

 * {
     box-sizing: border-box;
     padding: 0;
     margin: 0;
 }

 :root {
     /* --secondary: rgb(53, 53, 67); */
     --primary: #962E2A ;
     --secondary: #E3867D;
     --preSecondary: #CEE6F2;
 } 

  body{
    margin: 0px;
    font-family: -apple-system, "Helvetica Neue", sans-serif, "Mier Book";
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.5px;
    text-align: left;
  }
