.home-firstSection{
    width: 100%;
    background-color: rgb(248, 248, 255); 
    justify-content: space-between;
}

.home .firstSection-Cnt{
    flex-direction: column;
    min-height: 340px;
    padding: 30px;
    max-width: 55%;
    justify-content: flex-start;   
}

.home .firstSection-Cnt>h2{
    font-size: 3rem; 
    display: block;
}

.home .firstSection-feature{
    background-color: white; 
    margin: 2.3rem 0;
    width: 90%;
}

.home .firstSection-feature-item{
    padding-left: 8px;
    width: 33%;
}

.home .firstSection-feature-item-img{
    height: 40px;
    width: 40px;
    border-radius: 50%;
    border: 1px solid lightgray ;
    overflow: hidden;
    margin-right: 5px;  
}

.firstSection-feature-item-img img{
    width: 100%;
    height: 100%;
}

.home .firstSection-feature-item span{
    max-width: 80px;
}

.home .download-app-home-button{ 
    background-color: var(--primary);
    color: white;
    padding: 10px 20px;
    margin: 10px 0; 
    display: inline-flex;
    align-items: center;
    cursor: pointer;
}

.home .download-app-home-button img{
    margin: 0 1rem;
}

.home .firstSection-Img {
    display: flex;
    width: 45%;
    min-height: 370px;
    position: relative;
}
.home .firstSection-Img img{
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0; 
}


/* ------------------------------RESPONSIVENESS BREAK POINTS------------------------- */
@media screen and (max-width: 1024px) {
    .home .firstSection-Cnt{
        padding: 30px 5px 30px 20px;
    }
}


@media screen and (max-width: 769px){
    
    .home-firstSection {
        flex-direction: column;
        justify-content: center;
    }

    .home .firstSection-Cnt{
        max-width: 90%;
        align-items: center;
        text-align: center;
        justify-content: center;
    }

    .home .firstSection-feature{
        width: 100%;
    }

    .home .firstSection-Img{
        width: 90%;
        margin-top: 1rem;
    }
}