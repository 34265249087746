.header-second-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
    position: relative;
    z-index: 2;
    min-height: 45px;
}

.header-second-section-item {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0 20px 2px;
    border: none;
    min-height: 45px
}

.header-second-section-item span {
    letter-spacing: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    min-height: 45px;
    width: 100%;
}

.header-secondSec-drop-cont {
    display: none;
    justify-content: flex-start;
    position: absolute;
    top: 103%;
    left: 50px;
    right: 50px;
    border: 1px solid rgba(128, 128, 128, 0.493);
    border-top: 0;
    background-color: white;
    box-shadow: 2px 2px 5px lightgray;
    animation: 400ms ease-in 0s 1 normal none running fadeIn;
    z-index: 10;
}

.header-second-section-item:hover {
    padding-bottom: 0;
}

.header-second-section-item:hover span {
    color: var(--primary);
    border-bottom: 3px solid var(--primary);
    font-weight: 500;
}

.header-second-section-item:hover .header-secondSec-drop-cont {
    display: flex;
}

.header-secondSec-drop-items {
    padding: 0;
    background-color: white;
    border-top: 0;
    display: flex;
}

.header-secondSec-drop-items>div:nth-child(even) {
    background-color: rgba(173, 216, 230, 0.301);
}

.header-secondSec-drop-items>.item {
    border-radius: 5px;
    min-width: 150px;
    padding: 1rem;
}

.header-secondSec-drop-items>.item>h3 {
    margin-bottom: 15px;
    color: var(--primary);
}

.header-secondSec-drop-items>.item>p {
    letter-spacing: 1px;
    line-height: 1.7rem;
    cursor: pointer;
    opacity: 0.6;
    color: black;
}

.header-secondSec-drop-items>.item>p:hover {
    opacity: 1;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}


/* ------------------------------RESPONSIVENESS BREAK POINTS------------------------- */
 
@media screen and (max-width: 769px) {

    .header-second-section-item.home-list,
    .header-second-section-item.beauty-list,
    .item.sleewear {
        display: none;
    }

    .header-second-section-item {
        padding: 0 10px 0;
    }

    .header-second-section {
        padding: 0 10px;
    }

    .header-secondSec-drop-cont {
        left: 10px;
        right: 10px;
    }

}