.productCnt, .products, .productCnt .productDetail, .productFilter, .productFilterList{
    display: flex; 
    justify-content: center;
}

.productCnt{
    width: 100%;
    margin-top: 100px;
}
 
.productFilter{
    justify-self: flex-start;
    background-color: white;
    min-width: 20%;
}

.productFilterList{
  width: 100%;
}

.sortingCnt{
    width: 100%;
    position: relative;
}
 
.productFilter .sortBy{
    display: flex;
    gap: 10px;
    padding: 0.4rem 1rem;
    width: 100%;
    margin-bottom: 10px;
    border: 1px solid rgba(128, 128, 128, 0.171); 
    align-items: center;
    cursor: pointer;
    border-radius: 2px;
}

.byTag{
    opacity: 0.6;
}

.productFilter .sortBy .sortIcon{
    position: absolute;
    right: 5px;
    top: 5px;
} 

.sortingList{
    border: 1px solid rgba(128, 128, 128, 0.171); 
    cursor: pointer;  
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
}

.sortingList p{
    cursor: pointer;
    padding: 0.5rem 1rem;
    cursor: pointer;
}

.sortingList p:hover{
    background-color: rgba(187, 240, 222, 0.548);
}



.productCnt .products{
    flex-wrap: wrap; 
    gap: 20px; 
}    

 
.productCnt .productDiv{
    display: flex;
    justify-content: center; 
    flex-direction: column;
    max-width: 240px;
    border: 1px solid rgba(128, 128, 128, 0.171);
    border-radius: 5px;
    padding: 10px;
    gap: 20px;
}
.productCnt .productImgCnt{
    display: flex; 
    justify-content: center;
}

.productCnt .productImgCnt img{
    width: 210px;
    height: 200px;
}

.productCnt .productDetail{
   flex-direction: column;  
   opacity: 0.8;
   gap: 10px;
   align-items: flex-start;
   padding: 5px;
}

.productCnt .productDetail .discountSpan{
    color: green;
    font-size: 14px;
    letter-spacing: -1px;
}

.productCnt .productDetail .freeDelivery-btn{
    background-color: aliceblue;
    padding: 0.4rem ;
    cursor: pointer;
    border-radius: 20px;  
    font-size: 14px;
}

.productCnt .productDetail .originalPrice{
    text-decoration: line-through;
    font-weight: 400;
    letter-spacing: -1px;
    font-size: 14px;
    margin: 0 5px;
}

.productCnt .customerReviews{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
}

.productCnt .customerReviews .rating{
    background-color: green;
    padding: 0.3rem 0.5rem 0.3rem 0.7rem;
    border-radius: 20px;
    color: white; 
    
}
.productCnt .customerReviews .rating span{
    position: relative;
    bottom: 1px;
}
.productCnt .customerReviews .reviews {
    font-size: 14px; 
}



/* ------------------------------RESPONSIVENESS BREAK POINTS------------------------- */

@media screen and (max-width: 1024px) {
    .productCnt .productDiv{ 
        width: 290px;
        max-width: 290px;
    }
}
